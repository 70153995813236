import { useRef, useState, useEffect, useCallback } from 'react'
import {Helmet} from "react-helmet";
import FlipPage from 'react-flip-page';
import Sidebar from "react-sidebar";

import books from './books'
const linkedBook = books.filter(book => book.path === window.location.pathname)[0]
const pageParam = new URLSearchParams(window.location.search).get('p')
const linkedPage = linkedBook && pageParam ? linkedBook.pages.findIndex(page => page.title === decodeURIComponent(pageParam)) : null
const password = "1aNapier"

function App() {
  const flipPage = useRef()
  const [auth, setAuth] = useState(window.localStorage.getItem('auth') === password)
  const [page, setPage] = useState(linkedPage ? linkedPage : 0)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [book, ] = useState(linkedBook)
  const [showCover, setShowCover] = useState(!linkedPage || linkedPage === "Title" ? true : false)
  // const [autoScroll, setAutoScroll] = useState(true)

  // AUTO PAGE TURN THING - kinda buggy
  // 
  // 
  // useEffect(() => {
  //   const scrollInterval = 30000;
  //   const scroll = async () => {
  //     while (autoScroll) {
  //       await new Promise(res => setTimeout(res, scrollInterval))

  //       if (!showCover) {
  //         flipPage.current.gotoNextPage()
  //       } else {
  //         setShowCover(false)
  //       }
  //     }
  //   }

  //   if (autoScroll) {
  //     scroll()
  //   }
  // }, [autoScroll, setAutoScroll, showCover])

  // TRYING TO FIX PAGE SCROLLING 
  // 
  //
  // const scrollPage = useCallback((e) => {
  //   console.log(e)
  // }, [page])

  // useEffect(() => {
  //   if (page) {
  //     console.log("added scroll")
  //     document.querySelector("#active-left").addEventListener("click", scrollPage)
  //     document.querySelector("#active-right").addEventListener("click", scrollPage)
  //   }
  //   return () => {
  //     document.querySelector("#active-left").removeEventListener("click", scrollPage)
  //     document.querySelector("#active-right").removeEventListener("click", scrollPage)
  //   }
  // }, [page, scrollPage])

  // Arrow key page turning
  const turnPage = useCallback((e) => {
    if (!book) return
    if (e.code === "ArrowRight" && page < book.pages.length - 1) setPage(page => page+1)
    if (e.code === "ArrowLeft" && page > 0) setPage(page => page-1)
  }, [page, book, setPage])

  useEffect(() => {
    document.addEventListener('keydown', turnPage)
    return () => document.removeEventListener('keydown', turnPage)
  }, [turnPage])

  useEffect(() => {
    if (flipPage.current) {
      flipPage.current.gotoPage(page)
      setSidebarOpen(false)
    }

    if (book) {
      document.querySelector("#root").style.backgroundColor = book.backgroundColor
      window.history.pushState({ page }, "", `${window.location.pathname}?p=${encodeURIComponent(book.pages[page].title)}`)
    }
  }, [book, page])

  return (auth ? book ? <>
    <Helmet><title>{`Ringroses.net | ${book.title} - ${book.pages[page].title}`}</title></Helmet>
    <Sidebar
      sidebar={<SideIndex book={book} setPage={setPage} />}
      open={sidebarOpen}
      onSetOpen={(open) => setSidebarOpen(open)}
      styles={{ 
        root: { pointerEvents: "none" },
        sidebar: { background: "white" }
      }}
    >
      <button id="sidebar-button" onClick={() => {
        setShowCover(false)
        setSidebarOpen(!sidebarOpen)
      }}>
        <p>{`Index`}</p>
      </button>
    </Sidebar>
    {showCover && <div className="book-cover" onClick={() => setShowCover(false)}>
      {book.cover}
    </div>}
    <FlipPage 
      ref={flipPage}
      className={showCover ? "book hidden" : "book"}
      orientation="horizontal"
      showTouchHint
      onPageChange={page => setPage(page)}
      pageBackground="linear-gradient(to right, whitesmoke, #eaeaea 49%, whitesmoke)"
      noShadow
      uncutPages
      flipOnTouch
      loopForever
      disableSwipe={true}
    >
      {book.pages.map((page, index) => <article key={index} className="book-article">
        <Left>{page.left}</Left>
        <Right>{page.right}</Right>
      </article>)}
    </FlipPage>
  </> : <div>
    <h2>Welcome to Ringroses.net</h2>
    <a href="/remembrance"><p>Remembering Brian & Margaret</p></a>
    <a href="/cookbook"><p>Cook book</p></a>
  </div> : <AuthBox setAuth={setAuth} />);
}

export default App;

const Left = ({ children }) => <div style={{ float: "left" }} id="active-left" className="page-half">{children}</div>

const Right = ({ children }) => <div style={{ float: "right" }} id="active-right" className="page-half">{children}</div>

const AuthBox = ({ setAuth }) => {
  const [value, setValue] = useState("")
  useEffect(() => {
    if (value === password) {
      window.localStorage.setItem("auth", value)
      setAuth(true)
    }
  }, [value, setAuth])

  return <div className="auth-box">
    <label htmlFor="auth-code">Please enter password for site access:</label><br />
    <input id="auth-code" name="auth-code" type="password" placeholder="password..." value={value} onChange={(e) => setValue(e.target.value)}></input>
  </div>
}

const SideIndex = ({ book, setPage }) => <div className="side-index">
  {book.pages.map((page, index) => <button key={index} onClick={() => setPage(index)}>{page.title}</button>)}
</div>