const book = {
    title: "Cook Book",
    path: "/cookbook",
    backgroundColor: "cornsilk",
    pages: [
        {
            title: "page 1",
            left: <>
                <h1>Welcome to the Ringrose Cook Book!.</h1>
                <p>asdasdad</p>
                <p>asdasdad</p>
                <p>asdasdad</p>
                <p>asdasdad</p>
                <p>asdasdad</p>
                <p>asdasdad</p>
                <p>asdasdad</p>
                <p>asdasdad</p>
            </>,
            right: <h1>This is where there will be some nice pics and text.</h1>
        },
        {
            title: "page 2",
            left: <h1>Hi, this is a really long line of text that is on the left side on the page.</h1>,
            right: <h1>Hi, this is a really long line of text that is on the right side on the page.</h1>
        },
        {
            title: "page 3",
            left: <h1>Hi, this is a really long line of text that is on the left side on the page.</h1>,
            right: <h1>Hi, this is a really long line of text that is on the right side on the page.</h1>
        }
    ]
}

export default book