const book = {
    title: "Memories of Brian and Margaret",
    path: "/remembrance",
    backgroundColor: "skyblue",
    cover: <>
        <h1>Remembering Brian & Margaret</h1>
        <strong>Brian Sefton Ringrose</strong>
        <p>1931 - 2020</p>
        <strong>Margaret Jean Ringrose</strong>
        <p>1928 - 2019</p>
        <p><i>Click to see pages.<br></br>Click page edges or use index to navigate.</i></p>
    </>,
    pages: [
        {
            title: "Title",
            left: <>
                <h2>Remembering Brian & Margaret</h2>
                <strong>Brian Sefton Ringrose</strong>
                <p>1931 - 2020</p>
                <strong>Margaret Jean Ringrose</strong>
                <p>1928 - 2019</p>
                <span>
                    <img style={{ width: "180px" }} src="/images/brian-1.png" alt="brian-1"></img>
                    <img style={{ width: "180px" }} src="/images/brian-margaret-1.png" alt="brian-margaret-1"></img>
                </span>
            </>,
            right: <>
                <h2>Brian was quite an archivist!</h2>
                <p>So here is just a glimpse of some of the things that were important to Brian</p>
                <img src="/images/brian-collections.png" alt="brians-collection"></img>
            </>
        },
        {
            title: "Love Letters",
            left: <>
                <h2>Brian wrote many love letters</h2>
                <p>Here is just one birthday wish to Margaret</p>
                <p>With some humour thrown in!</p>
                <img style={{ margin: "1rem" }} src="/images/birthday-letter1.png" alt="birthday-letter1"></img>
            </>,
            right: <>
                <img style={{ margin: "1rem" }} src="/images/birthday-letter2.png" alt="birthday-letter2"></img>
                <img src="/images/birthday-letter3.png" alt="birthday-letter3"></img>
                <img src="/images/birthday-letter4.png" alt="birthday-letter4"></img>
            </>
        },
        {
            title: "Letters Around The World",
            left: <>
                <h2>And letters from all around the world</h2>
                <p>Especially treasured were letters from their children ...</p>
                <p>... Alison was the most faithful correspondent</p>
                <img src="/images/letter-1.png" alt="letter-1"></img>
            </>,
            right: <>
                <img src="/images/letter-2.png" alt="letter-1"></img>
            </>
        },
        {
            title: "Wedding Photos",
            left: <>
                <h2>Wedding photographs of course</h2>
                <p>Note how much Brian was smiling!</p>
                <p>Something he kept up all his life</p>
                <img src="/images/wedding-1.png" alt="wedding-1"></img>
            </>,
            right: <>
                <img src="/images/wedding-2.png" alt="wedding-2"></img>
            </>
        },
        {
            title: "More Wedding Photos",
            left: <>
                <h2>He smiled at everyone's wedding</h2>
                <img style={{ width: "300px" }} src="/images/tl-wedding-1.png" alt="tl-wedding-1"></img>
                <img style={{ width: "300px" }} src="/images/tl-wedding-2.png" alt="tl-wedding-2"></img>
                <p>The Wedding of Tim and Louise</p>
            </>,
            right: <>
                <img src="/images/pp-wedding-1.png" alt="pp-wedding-1"></img>
                <p>The Wedding of Philip and Priscilla</p>
            </>
        },
        {
            title: "Retirement Flat",
            left: <>
                <h2>It is obvious they enjoyed their retirement in the flat in Edinburgh</h2>
                <img src="/images/retirement1.png" alt="retirement1"></img>
                <p>Here on their holiday to Cyprus</p>
            </>,
            right: <>
                <img src="/images/retirement2.png" alt="etirement2"></img>
            </>
        },
        {
            title: "Retirement Holiday",
            left: <>
                <h2>A nostalgic trip to Yorkshire</h2>
                <img src="/images/retirement3.png" alt="retirement3"></img>
                <p>Brian went to boarding school at Giggleswick (including durinng the war) and did a special trip there with Margaret</p>
            </>,
            right: <>
                <img src="/images/retirement4.png" alt="retirement4"></img>
            </>
        },
        {
            title: "Letters From India",
            left: <>
                <h2>There were many tokens of thanks</h2>
                <p>And letters of appreciation for all that Brian and Margaret did while working in India, and then later in supporting Interserve and <i>Friends International</i> thoughout the Middle East and Asia</p>
                <span>
                    <img style={{ margin: "1rem", width: "150px" }} src="/images/india-1.png" alt="india-1"></img>
                    <img style={{ margin: "1rem 0", width: "220px" }} src="/images/india-2.png" alt="india-2"></img>
                </span>
                <p>Ringrose family - Bombay 1968</p>
            </>,
            right: <>
                <img src="/images/friends-international-1.png" alt="friends-international-1"></img>
            </>
        },
        {
            title: "Documents from India",
            left: <>
                <h2>The 1970s road map of India gives some insights</h2>
                <img style={{ margin: "1rem" }} src="/images/india-3.png" alt="india-3"></img>
            </>,
            right: <>
                <img style={{ margin: "1rem" }} src="/images/india-4.png" alt="india-4"></img>
            </>
        },
        {
            title: "Documents from India 2",
            left: <>
                <h2>But best documented in the archives are sermon notes</h2>
                <p>Resources and notes for bible studies, sermons and advice to study groups</p>
                <img style={{ margin: "1rem" }} src="/images/bible-1.png" alt=""></img>
            </>,
            right: <>
                <img style={{ margin: "1rem" }} src="/images/bible-2.png" alt=""></img>
            </>
        },
        {
            title: "Brian's Notebook",
            left: <>
                <h2>Here are some snippets of wisdom from Brian's notebook</h2>
                <img style={{ margin: "1rem" }} src="/images/bible-3.png" alt="bible-3"></img>
                <img style={{ margin: "1rem" }} src="/images/bible-4.png" alt="bible-4"></img>
            </>,
            right: <>
               <h2>His sermons were about practical Christian faith in the real world</h2>
                <p>Like life in urban Bombay (Mumbai) in the 1970's</p>
                <img src="/images/bible-5.png" alt="bible-5"></img>
                <p>An extract from a Brian Ringrose sermon</p>
                <img src="/images/bible-6.png" alt="bible-6"></img>
            </>
        },
        {
            title: "Their Children",
            left: <>
                <h2>And most treasured were family gatherings</h2>
                <p>Photos of their children, Annette, Tim, Alison and Philip ...</p>
                <img src="/images/family-1.png" alt="family-1"></img>
            </>,
            right: <>
                <p>... and their 11 grandchildren, Lauren, Douglas, Christy, Juliette, Seb, Fred, Miriam, Louis, Evan, Daniel and Conor.</p>
                <img src="/images/family-2.png" alt="family-2"></img>
                <p>Brina on the beach in Kinlochbervie in 2002 with grandson Louis</p>
                <img src="/images/family-3.png" alt=""></img>
            </>
        },
        {
            title: "Eulogies: Robin Thomson & Norris and Sheelash Thompson",
            left: <>
                <h2>Robin Thomson</h2>
                <strong>Remembering Brian</strong>
                <p>When I first joined BMMF as a very raw recruit in 1967 (one of the ‘Field Partners’ in those days), Brian came to Chennai to visit me. He was of course checking me out, but our conversation was so relaxed and easy and he made me feel very welcome.  That was always my impression of Brian, warm, relaxed, caring and wise. After Shoko and I were married, when she had some health problems we visited Brian and Margaret where they were staying in Coonoor and experienced again that combination of care and wise advice. Shoko and I were always grateful for their friendship and pastoral care while we were in India.</p>
                <p>Several years later I visited them more than once in Scotland for Interserve conferences and a mission trip, in which I greatly enjoyed their hospitality and companionship. Brian’s leadership of Interserve Scotland, as I observed it, was again relaxed, warm and personal, with a deep and inspiring commitment to mission.</p>
                <p>I thank God for Brian and Margaret’s friendship and their great example.</p>
            </>,
            right: <>
                <h2>Norris and Sheelagh Thompson</h2>
                <p>We have many very happy memories of our times of friendship with Brian and Margaret when they lived in Lenzie in the 1980 s and 1990 s. We enjoyed meals together, times of prayer &amp; fellowship and meeting many missionary colleagues and friends. Their home in Elm Avenue was always open and welcoming. They contributed much to the life of Lenzie Union church and the work there. I have particular happy memories of Margaret at the Thursday morning ladies’ Bible Study. She would cycle round to our house each Thursday over many years, and was such a bright and encouraging member of the group. Her contributions to our discussions were always deep and most helpful, and her love for the Lord shone through all she said.</p>
                <p>We thank God for every remembrance of Brian and Margaret.</p>
            </>,
        },
        {
            title: "Eulogies: Bruce Nicholls & Jim and Pam Parrat",
            left: <>
                <h2>Bruce Nicholls</h2>
                <p>Brian was just four years younger than me  and together with our respective wives, Margaret and Kathleen,  we joined ZMMF in 1950 s to serve in India.</p>
                <p>I always enjoyed working with Brian,  though he was in Bombay and I was in Yeotmal 500  miles to the east. . We met often in ZBMMF activities and shared holidays together at Edgehill, Landour, Mussoorie.</p>
                <p>Brian was a kind and compassionate man. He loved Indian and her people. He was one of the few missionaries invited to become the pastor of an Indian congregation. Brian served with the Church of North India in a parish in Bombay for many years. He was a devoted husband,  a faithful follower of Jesus.  joyous and kind as the photo of him you have given us shows.  He is now with his Lord and with Margaret,  where he awaits the resurrection to his heavenly body.</p>
            </>,
            right: <>
                <h2>Jim and Pam Parrat</h2>
                <p>We pray for a peaceful - and when it comes - joyous meeting with the Lord.  You bring back to us so many special memories! Beginning in a strange way; you invited me to join the Interserve Council (still not sure why!) and the letter was lost under piles of correspondence for some time.  Maybe a year!  When I did reply we met and this started, for the two of us - Pam and I - a very special friendship with you both.  So many times we stayed with you in your lovely Edinburgh flat, so much music, so much chatter over prolonged breakfasts.  You even got as far as Bearsden! Thank you Brian for the joy and spiritual blessing of you brought into our lives.</p>
                <p>We give thanks to the Lord for every remembrance of you.  Go well.</p>
                <p>Shalom, dear friend.</p>
            </>,
        },
        {
            title: "Eulogies: John Mason",
            left: <>
                <h2>John Mason</h2>
                <p>Brian has had a huge influence on my life and it is difficult to know what to say and what to leave out.</p>
                <p>I first met Brian when the United Mission to Nepal suggested I could go out to work there with BMMF (Interserve). I very quickly felt at home in the BMMF family and that was mainly down to Brian (and Margaret) and their welcome. It was only a few months after this that I arrived in Nepal in 1984.</p>
                <p>A key issue was that neither my parents nor my church (Stonelaw Church of Scotland) knew anything much about BMMF or about things like team support. So Brian set about building relationships with both. This was one of his key strengths in my opinion… being able to relate so well to a whole range of Christians and churches right across Scotland.</p>
                <p>Brian visited myself and other Scottish partners in Nepal and it was great to have that strong link with Interserve at home. I remember colleagues from England saying how they wished they could have that same personal relationship with the home leadership.</p>
            </>,
            right: <>
                <p>After my 3 years in Nepal I took on the role of treasurer for Interserve Scotland and that involved many visits to Lenzie and the Ringrose home/office. As you can imagine I was the recipient of many teas, coffees, and meals courtesy of Brian and Margaret.</p>
                <p>Later I became a member of Interserve’s International Finance Committee which meant Brian and myself being at meetings in Cyprus and elsewhere on an annual basis. Again I could see him relating so well with, and being respected so much by, Christian leaders from all around the world. On one occasion he and I were travelling back from Nicosia on a flight somewhere in the middle of the night. So first we went to see a late night film. It was ‘Forest Gump’ and we both thought it was so poor that we left part way through!</p>
                <p>A number of Christian leaders have had a big influence on me and have been a great encouragement in my life. But Brian is part of a small group right there at the top. Praise God for his life.</p>
            </>,
        },
        {
            title: "Eulogies: Rosamond and Ian Robertson & Marian Burrow-Smith",
            left: <>
                <h2>Rosamond and Ian Robertson</h2>
                <p>Brian radiated contentment and good humour. Though very able and excelling in whatever he chose to apply himself to, whether a sermon, bread making or growing produce at their allotment, he was modest yet recognised as a quiet authority in the groups he was part of. Brian and Margaret were living examples of the faith they professed and their underlying clarity of purpose caused them to be hugely productive yet never giving the impression of busyness or haste.</p>
                <p>They delighted in their remarkable children and grandchildren and were a model and inspiration to us in the weekly Home Group that often met in our home. We benefited from their wisdom and hospitality, their congenial company and wonderful stories of their time in India. We have continued to hold them as a couple in our memory and are pleased to think of them together once more.</p>
            </>,
            right: <>
                <h2>Marian Burrows-Smith</h2>
                <p>As new, aspiring Interserve Scotland partner, I will always remember  being warmly welcomed into their home in  Elm Avenue, Lenzie for my first orientation. I slept in the little cubby hole up some steep steps which they had created. Their home was always welcoming. The last time I saw them was in the flat near Napier College, staying over after speaking at an Interserve prayer meeting.  They were true Christian, warm hearted people.</p>
            </>,
        },
        {
            title: "Eulogies: Christine and Peter Hill",
            left: <>
            <h2>Christine and Peter Hill</h2>
                <p>We first met Brian and Margaret at our first BMMF conference held at High Lee in Hertfordshire.  We had just been accepted by the Personnel Committee to work with BMMF in Vellore, S. India.  I was taken for a walk in the garden by one of the single ladies on the Committee and told that the Mission had decided that Partners themselves would have to pay  for the support of a child if they had more than three children.  I was completely taken aback by this information coming from this lady and burst back into the centre to tell Margaret what had just been said.   Rather angrily she exclaimed “yes, I know, it’s a terrible decision”, which is when I found out that she was expecting her fourth baby!   I felt very sad for her and Brian.</p>
                <p>Our next encounter was with Brian when he came to speak at our church in Birmingham at our commissioning service.   We were very grateful to him, presumably when he was on, what we used to call, furlough.   We sang “We rest on thee our shield and our defender” which was an encouragement as we faced the unknown in India with two small children.</p>
            </>,
            right: <>
                <p>Our third special memory was in 1974 in Bombay.   When we arrived our first time in India, after a very long plane journey and difficulty getting through a packed customs hall we went to stay at Queen Mary High School where, because it was monsoon time, everything smelled musty and it was extremely noisy with traffic and trains seeming to go round the school in all directions, and was under the stern eye of Betty Shelton.   I was so grateful to fly down to Madras and be taken to Vellore, which seemed heaven as compared with horrible Bombay.   However, when we were on our way out of India to UK four years later, now with 3 children, we stayed with Brian and Margaret in their lovely house on Malabar Hill and Bombay seemed to be a much nicer place than I had thought.   Brian and Margaret told us that it is important to find some place of  beauty and tranquility where you can relax, in India, if you are going to survive the grimmer aspects of that country in the 1970’s.</p>
                <p>We certainly experienced the truth of that and appreciated the  importance of finding opportunities to escape the business of life by enjoying beautiful places in India during our subsequent six years in Vellore and  the visits we have made since 1980.</p>
            </>,
        },
        {
            title: "Eulogies: Margaret Parkinson & Bruce Nicholls",
            left: <>
                <h2>Margaret Parkinson</h2>
                <p>Our paths crossed for the first time in March 1965 when I arrived in Nasik to commence Marathi language study. I stayed at the Christian  Writing Institute.   Your Dad encouraged me to study well to help in understanding the culture and communicating with others.</p>
                <p>Their hospitality was a model for me to follow during my years of living abroad.</p>
                <p>I lived in both Solapur and Pune when Brian was Vicar of All Saints Church Mumbai.  I visited them at the vicarage and was amazed how well your Mother managed their home.  She was quite incredible.</p>
                <p>I loved being in Mumbai on Sundays and to hear Brian preach with such clear and challenging messages.</p>
                <p>I  was present  in Pune when Annette  was born  at the Wadia Hospital.  Your mother's  joy to welcome a second daughter was so infectious.</p>
            </>,
            right: <>
                <h2>Bruce Nicholls</h2>
                <p>During our years at the Union Biblical Seminary in Yeotmal central India, Kathleen and I sometimes had an occasion to go to Bombay. We were always blessed by the time we spent with Brian and Margaret.</p>
                <p>I greatly admired Brian’s pastoral gift as the pastor of an important CNI church. He had a true pastoral heart and was much loved by his Indian congregation and his fellow missionaries.</p>
            </>,
        },
        {
            title: "Eulogies: David and Frances Hawkey &  Adam Sibley",
            left: <>
                <h2>David and Frances Hawkey</h2>
                <p>We remember particularly the annual nativity plays at All Saints - especially "Amahl and the Night Visitors" in which all four of our children were involved, even if only as sheep for the younger ones!</p>
                <p>With our love and blessings as you put many memories together.  A great idea!</p>
                <p>Frances and David</p>
            </>,
            right: <>
                <h2>Adam Sibley</h2>
                <p>It was an honour to attend the services of thanksgiving for Margaret on behalf of Interserve GBI and to briefly meet Brian. Their legacy and service will not be forgotten. I wish I could be there in person to honour a great man who did so much for others.</p>
            </>,
        },
        {
            title: "Eulogies: Elizabeth Curry",
            left: <>
                <h2>Elizabeth Curry</h2>
                <p>I well remember meeting Brian and and Margaret with Philip as they arrived off the train from Mumbai (Bombay) at Pune (Poona). There was a considerable amount of luggage which required more than one taxi. They were planning to join the language school at Mahableshwar in the hills of Maharashtra. Those of us already arrived there had moved an assortment of furniture of one design into a room for the new family, to produce some sort of order for this initial adjustment for them. However the price required by the taxi-walas was exhorbitant, so I set about engaging others. I felt ashamed of not having done that first! Only some years later did I discover that Briana and Margaret had been very impressed!</p>
                <p>When they were ready to explore the surrounding area I offered to look after Philip who was 9 months old, but was very firmly told by Brian that he was not to be be removed from their care. We were a group of 4 single English  ladies, joined later by a Canadian one and a New Zealand couple. An interesting mixture of relationships ensued!</p>
            </>,
            right: <>
                <p>After language school I returned to Manoram Sadan, a children's home, where I was the nurse in charge of their health, and Brian and Margaret came to continue language study with me. Before very long Margaret let us know that she was pregnant. She saw a Dr in Pune but they weren't able to ascertain when the baby would be born (Those were the days before scans were available). I resolved not to get involved although I was a midwife as too much responsibility would fall on my head delivering someone in the mission. But a national on the compound was also pregnant so I gathered together what I thought would be helpful for her delivery. However, my senior refused to let me  to do anything for her as there was a hospital in the town. This was very fortuitous as one morning at 5.30am Brian came to ask me to see Margaret as she couldn't deal with a pain she had. It turned out that Alison was about to appear! I had learnt from a friend that the size of Philip's head had caused some difficulty to Margaret, but all I could think of was collecting my prepared things and give advice to Margaret and Brian. Thankfully all went well and Margaret was happy to receive my care.</p>
                <p>Brian and Margaret moved on to the Bible Fellowship Centre in Nasik and later to a Church in Mumbai while the children were educated at Hebron School in S. India.</p>
            </>,
        },
        {
            title: "Eulogies: Kit and Angie Inchley & Lionel and Christine Holmes",
            left: <>
                <h2>Kit and Angie Inchley</h2>
                <p>As you may imagine, with such a long connection (dating back to Abersoch in the mid-50s in my case) we would have loved to attend, and to join with you in celebrating their lives (but sadly we will not be able to make that date).</p>
                <p>We so much enjoyed reacquainting ourselves with them both during their retirement in Edinburgh, being immensely grateful for Brian’s ministry at church, for their friendship, and for invitations to lunch at the flat in Napier Road. We treasure the memory of them both.</p>
            </>,
            right: <>
                <h2>Lionel and Christine Holmes</h2>
                <p>We have very warm memories of Brian & Margaret but only after they had returned to the UK from India; even though our short-term service there must have briefly overlapped with theirs, we never met them overseas.</p>
                <p>Brian & Margaret were exceptionally hospitable, and we enjoyed the fruits of that on at least two occasions – at Lenzie and in Edinburgh when they lent us their accommodation while they were away.  We also called on them after they had moved south to Wiltshire, in both Crudwell and Malmesbury, while we managed a final visit to Brian, not long after Margaret had died, in the residential home in Tetbury. He was still his bright self, and seemed quite undimmed by age!</p>
                <p>We treasure the memory of Brian & Margaret, and are grateful to have known them as well as we did.</p>
            </>,
        },
        {
            title: "Eulogies: Mike and Moira Stewart",
            left: <>
                <h2>Mike and Moira Stewart</h2>
                <p>A few thoughts on Margaret and Brian. Firstly Brian, I still remember him taking a service at Lenzie Union many many years ago with the Monkey's paw story for the children's address followed by sermon on PS 117. Such a peaceful smily gentle giant - and we really loved your Christmas parties! And wonderful how Brian recovered from his stroke under your Mum's faithful loving care after being taken out of a concert and straight into hospital - no hanging about waiting for him. God is SO good.</p>
                <p>Margaret was such a faithful praying friend and it was a real joy to pray with her every week for so many years - and even be ministered to with delicious celery soup when I wasn't well. Margaret was a real treasure and I would have been bereft when they left Elm Ave but we left first!</p>
                <p>Mike's contribution.  They were authentic. You knew they loved the Lord and they both had a great sense of humour. Is it a bing or a bong?</p>
                
            </>,
            right: <>
                <p>Also when we visited  Ps and Gs with Brian and Margaret, Geoff Grogan was preaching and in the children's address he taught us how to make gravy! No idea of the Gospel relevance now!</p>
                <p>On another visit to Edinburgh, in April 2006, Mike was just about to start a new job in South Africa and Margaret gave him a verse as we left in the morning.</p>
                <p>We visited folk in St Catherine's, Argyll on Loch Eck that night and their Bible study were focussing on the same verse which was absolutely right for his new job! Does the Lord speak or does the Lord speak?</p>
            </>,
        },
        {
            title: "Eulogies: Peter Yacomeni & Farouq Omaro",
            left: <>
                <h2>Peter Yacomeni</h2>
                <p>Margaret. Her breaking into song at Home Group -often very appropriately- and her smile.</p>
                <p>Brian. A shared love of music -he put us on to the Berlin Philharmonic site.</p>
            </>,
            right: <>
                <h2>Farouq Omaro</h2>
                <p>The late Brian Ringrose and his wife were really hospitable, kind and generous. When all of my other colleagues had foster families, I had none. Brian found out from my friend and called me up. He and Margaret would invite me to their home on Napier Road to have lunch. They would also drive us around Edinburgh and take us for walks. Though our age gap was huge, I never felt it.</p>
                <p>I am saddened to learn of his passing and regret that I could not have arrived back sooner in Scotland. Anyway I am sure the Lord has better plans for him and Margaret. God bless their souls and may they rest in peace. I also would like extend my condolences to their children.</p>
            </>,
        },
        {
            title: "Eulogies: Calum and Liz Ferguson",
            left: <>
                <h2>Calum and Liz Ferguson</h2>
                <p>How do you find words to describe the dearest people whose lives radiated love, joy, peace, patience, kindness, goodness, faithfulness, gentleness and self control. They lived by the Spirit and kept in step with the Spirit.</p>
                <p>I have many memories, but what I would like to share is the inspiration they were to me in their response to Brian’s stroke.</p>
                <p>They held an Interserve prayer group in their home on Napier Road, at which Brian would share a message from the Bible, and he and Margaret would share the matters for prayer.</p>
                <p>After his stroke, Brian was not able to speak for a while. Many a couple would have used this as an opportunity to take a break from hosting the meeting. They did not, but bravely kept it going. Margaret then shared some encouraging words of scripture and prayer topics, and Brian sat in his chair, praying inaudibly and so much with us. I was very touched by Brian’s patience and Margaret’s fortitude. She led the meeting so humbly and so practically.</p>
            </>,
            right: <>
                <p>Brian worked very hard at learning to speak again, and when he then prayed audibly for the first time, it was very precious. When he first shared a message from the Bible, I wished I had written it down as it was so “godly”.</p>
                <p>They were both so positive and encouraging, and were such a blessing to so many of us in our walk with the Lord.</p>
                <p><i>
                    “The godly will flourish like palm trees and grow strong like the cedars of Lebanon.<br />
                    For they are transplanted into the Lord’s own house.<br />
                    They flourish in the courts of our God.<br />
                    Even in old age they will still produce fruit;<br />
                    They will remain vital and green.<br />
                    They will declare, 'The Lord is just! He is my rock! There is nothing but goodness in him.'"<br />
                    </i>Psalm 92 verses 12 - 15.
                </p>
                <p>As one who was privileged to be in their wide circle of friends, I loved them very much, and rejoice to think that in Christ we will meet again.</p>
            </>,
        },
        {
            title: "Eulogies: Shirley Fraser",
            left: <>
                <h2>Shirley Fraser</h2>
                <p>I met Brian and his beloved wife, Margaret, through becoming a member of the Personnel Committee of BBMF and subsequently also the Scottish Council of Interserve.</p>
                <p>I will never forget the warmth of the welcome and the hospitality of their Elm Avenue home which doubled as the office. Brian could be relied on to give wise, discerning comments and unobtrusive guidance for us on the Committee, and I know he and Margaret were deeply appreciated by our Scottish partners too.</p>
                <p>In time they moved to their beautiful upper flat in Napier Road, Edinburgh and I was a frequent visitor, as I moved from Aberdeen in 2001 to become a partner with Friends International. From the start, Brian was a faithful supporter in prayer and in financial giving. He and Margaret hosted our monthly Saturday morning breakfast prayer meeting (and that did include a cooked breakfast!) I also relaxed with them as we enjoyed the music of Bach together.</p>
            </>,
            right: <>
                <p>Brian’s stroke changed some things, but not his smile and twinkling eyes, even when his speech was frustratingly limited and he had to spend a long time in Rehabilitation in the Astley Ainslie Hospital, where I visited him. By his perseverance and many prayers for him, he made a considerable degree of recovery.</p>
                <p>However, a move south to be close to Annette and family in Crudwell was timely, and I was able to stay with him and Margaret in their quaint house on the Tetbury Road junction. They enjoyed having Annette and their grandsons coming in and out of their home, of course.</p>
                <p>Their second last move was into the beautiful town of Malmsbury, and again I was able to visit them, staying in the Guest Room of the Retirement complex. Brian even lent me his car so that I could drive south to visit my elderly relation in a wee village in Dorset. That was the last time as I was actually with them, however, as it became clear that first Margaret and then Brian would require the care of the Nursing Home in Tetbury.</p>
                <p>Thankfully, I have retained the Christmas card they sent in 2016, with its lovely photo of them both standing in front of The Old Bell. My chief memory of Brian is of a wonderful, humble, godly man, who with Margaret, enriched my life in so many ways.</p>
            </>,
        },
        {
            title: "Eulogies: Jacqueline and Cathel Trinder",
            left: <>
                <h2>Jacqueline and Cathel Trinder</h2>
                <p>Cathel &#38; I very much enjoyed our years in the pews with Margaret and Brian but it was in the last few years that we got to know them more personally, with giving them a lift to the 9am service. I had always enjoyed the occasions Brian preached because I had something to go and mull over for the week. In particular, I noticed how often he drew on the wisdom of the early church fathers and others through the history of the church. Likewise I noticed, when Margaret led the prayers of intercession, that she often had a springboard of a quote. This drawing in of such experience seemed to me to add a great richness and sense of connectedness to the body of Christ through ages and cultures.</p>
                <p>We were sad that, following his stroke, Brian was not able to take up his preaching role again at church. However, we were delighted when Brian &#38; Margaret agreed to lead a smaller scale retreat with 13 of us at Whitchester in February 2006. It was billed as a tour of writings from 14th - 20th centuries. Brian did a session: The life of St Theresa of Avila - stages of prayer, and another session: Comparison: Julian of Norwich and Samuel Rutherford. Margaret then also led a session on Austin Farrer. It was a treat! We so enjoyed their gentle, kind, wise, and hospitable company.</p>
            </>,
            right: <>
                <p>We remember seeing Margaret cycling up Morningside Road, hearing about successes on the allotment, discussing Myers Briggs, enjoying food around the table, and benefiting from their tickets at the Queen’s Hall when they left Edinburgh before the concert.</p>
                <p>It will be a great joy to celebrate their lives and, no doubt, to hear much that we never knew!</p>
            </>,
        },
        {
            title: "Eulogies: Murray and Ruth McGavin & Jonathan and Juay Blythe",
            left: <>
                <h2>Murray and Ruth McGavin</h2>
                <p>Brian and Margaret, with our wonderful Interserve friends and team were always such a blessing...from day one! They gave us such a real sense of mission and secure leadership as we went out to our beloved Afghanistan. Thank you, Lord! Murray and Ruth McGavin</p>
            </>,
            right: <>
                <h2>Jonathan and Juay Blythe</h2>
                <p>Brian and Margaret are amazing beautiful people whose lives have impacted so many for the Lord and His glory.</p>
                <p>We are amongst the many many who are recipients of their caring and nurturing ministry. They are together now, and most of all in the Lord’s presence. We can just hear the Lord welcoming them into His glory.</p>
                <p>Thou good and faithful servants...enter thy rest.</p>
            </>,
        },
        {
            title: "Eulogies: Margaret Cashman",
            left: <>
                <h2>Margaret Cashman</h2>
                <p>A short tribute to Brian and Margaret? It is impossible to sum up in a few words Brian and Margaret achieved and what they meant to their many friends. They had that unique gift of making warm friendships and offering practical help to those they met. Their family were just ahead of us in ages, and Philip’s first little waterproof jacket was passed from Philip to our Andrew, and, I think probably back again for Tim! They contacted us before we went to India, and gave us so many useful tips and bits of help. Once  out in India, we had holidays together in the hills “away from the heat down below” as one earnest missionary prayed one night at a prayer meeting!</p>
                <p>One holiday, when we had neighbouring houses, Margaret had the children up, fed, dressed, made a cake or two for later and half prepared the lunch, and then they called for us to go out. I think I was probably still trying to feed my family breakfast, and certainly wasn't ready for an expedition! One incident on one of these holidays stands out for me.</p>
            </>,
            right: <>
                <p>The two families had been to church together and on returning, dressed slightly more informally and we all set off to explore the woods. Milton found a handcart somewhere in the woods seemingly abandoned among the trees.  We loaded the children into it - I can't remember how many there were around at that time- and Brian took one handle and Milton the other, and pretending to be horses they pulled the cart along. Tremendous fun ensued until a Lutheran family passed us dressed extremely formally and properly on their way to Church, and they cast a disdainful look at the family jollity. Poor Brian was so embarrassed - beyond consolation I would say!</p>
                <p>I could fill a book myself with recollections of happy times together and through it all the living out of the faith in our Lord Jesus Christ which motivated them. Always a shoulder to cry on, sending food for a family going through a tough time and so on and so on. Time would fail me to tell of all the things we experienced together. Thank you Brian and Margaret for being a shining example to us all. Each could echo Paul’s words to the Philippians: “For me to live is Christ, to die is gain”</p>
            </>,
        },
        {
            title: "Eulogies: Alistair Morrice",
            left: <>
                <h2>Alistair Morrice</h2>
                <p>A tribute to Brian Ringrose.</p>
                <p>Brian Ringrose first came seriously into my life when I was invited to join the Scottish Council of Interserve most likely on the recommendation of Milton Cashman who was at that time lately returned from India to be in charge of BEPI an electronics company in Galashiels.</p>
                <p>Milton lived in Pune, not so very far from Brian who lived and served in the Anglican Diocese of Bombay.</p>
                <p>Milton had been largely instrumental in pushing for a separate Scottish Branch of Interserve and this came to pass with Brian as its secretary. The more high flown title of Director came later. At this time the Scottish council had about twelve partners, including Michael and Betty Roemmelle. Mike in due course served as the International Director of Interserve, so from the beginning although the Council represented a relatively small number of partners, it was highly respected among the international leadership of BMMF, later Interserve.</p>
            </>,
            right: <>
                <p>My own involvement started with the enthusiastic encouragement for me to have BMMF partners sharing in the life of our church in Galashiels and so they did. We learned about work in India, Nepal, Pakistan through different outstanding people who enriched my view of service across the world.</p>
                <p>So I joined the BMMF Council and learned much from those who served in that council, representatives from across the denominations who shared a common passion that the Gospel would be made known among all nations, and some of whom had themselves served abroad.</p>
                <p>I remember Brian first as the efficient, warm hearted secretary. Meetings were carefully prepared for, ongoing business covered, but nearly always there would be ideas for further development, reaching into churches, establishing together with others, a vision for reaching out to the world with Christ and His Gospel.</p>
                <p>In 1982 Brian asked if I would go as a third member in addition to himself and the chairman, (then George Dolby) to the Quadrennial Conference of the organization which was to be held in Delhi. But would I also join him on a trip to visit partners of ours in Kunri (South Pakistan), and would I like to be teamed up with friends of BMMF in Lahore to see something of the work of the church in the north of Pakistan.</p>
            </>,
        },
        {
            title: "Eulogies: Alistair Morrice 2",
            left: <>
                <p>I see now that I was being prepared without anything being said to have some leadership role. I think that quiet investment in people was one of Brian’s greatest strengths. Unassuming himself, he saw the gifts in others and wanted to see them used.</p>
                <p>That visit to India and more particularly Pakistan fundamentally changed my perception of what serving in another culture involved. In that visit, mostly due to the friendship of Hugh Gordon who served with another missionary organization based in Faisalabad and who worked intensively with pastors in the different denominations, I was introduced to Christians serving in a very different context, but who were, often against the odds, demonstrating the life of Christ in their culture. I could understand now so much better the situations into which Christian workers from another culture entered, and I could understand the life of the church where essentially these workers were servants coming alongside to help, but not to dominate.</p>
                <p>In course of time, George resigned from the chairmanship of Interserve, and Brian communicated that the Council would like me to be chairman. Having consulted with Mike who by this time was International Director, I agreed and so began some 7 years of close fellowship, working with Brian until he retired I think in 1996. This took us together to further quadrennial conferences and international meetings in Kathmandu, Karachi and Cyprus.</p>
            </>,
            right: <>
                <p>He was always keen to foster partnerships, and we sought to bring a more international perspective to the Church of Scotland who found it difficult to recognize the important place of the independent missionary society in world mission, and we went together to see if there were ways in which we could partner with the church in Egypt, although an Anglican, Brian and the family worshipped in Lenzie Union church initially with David Orrock as minister.</p>
                <p>Meantime in the international perspectives of Interserve, Korea was becoming a sending country, and other areas were coming within the vision of Interserve. Partners were already in Turkey, but what about the newly opened Central Asian republics?   Already we had an interest in Afghanistan, a deeply troubled nation to this day. (22 students killed in Kabul university this week!).</p>
                <p>In all of these developments, and more beside, Brian was a quiet, but assiduous supporter of visionary leadership, and the crossing of boundaries to serve as our name portrayed internationally, and holistically.</p>
            </>,
        },
        {
            title: "Eulogies: Alistair Morrice 3 & Rita Barwick",
            left: <>
                <p>When in course of time the particular vision of Interserve as a proponent of holistic ministry flowered, Brian was among those who took this up….and giving leadership to the national church, even when that produced difficult issues at times….he was unwavering in his conviction that the local church, where it existed must have the leadership, and control.</p>
                <p>Meantime partly through these international trips with Brian, invitations came to conferences of Partners in various countries, culminating in Kyrgyzstan in 1999. And that led as many of you know to our becoming partners ourselves from 2000-2008.</p>
                <p>As I reflect on Brian and Margaret’s ministry from a personal perspective, I do believe that his influence was far more pervasive on my life than I ever recognized at one particular time. I think God used him to embody a style of life, and a broadness of vision, and a committed practicality for which I am greatly in his debt. And if that was so for me, then for how many more, similar tales could be told.</p>
                <p>He enjoyed a long retirement for many years in which he remained solidly committed to the work of his local churches, and ongoing support for world mission.</p>
            </>,
            right: <>
                <p>He was always deeply engaged with  his wife and family whom he treasured, and they, him.</p>
                <p>At the last, he slipped away peacefully to the music of St Matthew’s Passion and no doubt awoke a little surprised to find himself home, renewing fellowship with so many who in different ways were indebted to his ministry around to welcome him. And of course, the Lord and he ‘face to face’ at last!</p>
                <h2>Rita Barwick</h2>
                <p>My association with your parents goes back to 1962. Lovely memories in all that we shared at the Bible Fellowship Centre in Nasik and later in Bombay, Scotland and Wiltshire.</p>
            </>,
        }
    ]
}

export default book